import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import EntityListRowForm from "../../../../components/Entities/EntityListRowForm";
import {PublicTransitLayerEntityName} from "../../../../api/entities/replancity_PublicTransitLine";
import {EntityServiceName} from "../../../../api/enums/enums";
import EntitiesLoader from "../../../../components/Entities/EntitiesLoader/EntitiesLoader";
import {useCloneTransitEntities} from "../../../../hooks/transitlLines/useCloneTransitEntities";
import {useToastContext} from "../../../../context/toastContext";
import {useTranslation} from "react-i18next";
import {getFilledFieldsObjOnFormSubmit, isErrorResponse} from "../../../../utils/utils";
import Portal from "../../../../components/Portal/Portal";
import {LoadingBackdrop} from "../../../../components/LoadingBackdrop/LoadingBackdrop";
import Modal from "../../../../components/Modal/Modal";
import {Form, Row} from "../../../../components/form/Form/Form";
import * as SelectBox from "../../../../components/form/SelectBox/SelectBox";
import {useEntitiesLoader} from "../../../../hooks/entites/useEntitiesLoader";
import {IconButton} from "../../../../components/buttons/IconButton/IconButton";
import {CopyIcon} from "../../../../components/icons/icons/CopyIcon";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import {CutIcon} from "../../../../components/icons/icons/CutIcon";
import {useMapFiltersContext} from "../../../../context/mapFiltersContext";


const TransitRoutesList = () => {
    const entityName = PublicTransitLayerEntityName.ROUTES;
    const serviceName = EntityServiceName.PUBLIC_TRANSIT;
    const {lineId} = useParams<any>();

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [copiedRouteId, setCopiedRouteId] = useState<string>('');
    const {loading, cloneRouteToLine, invertRoute} = useCloneTransitEntities();
    const {setMapFilter} = useMapFiltersContext();
    const {addToast} = useToastContext();
    const {t} = useTranslation();

    const {
        loading: linesLoading,
        entities,
    } = useEntitiesLoader({
        entityName: PublicTransitLayerEntityName.LINES,
        serviceName: EntityServiceName.PUBLIC_TRANSIT,
    });

    const linesOptions = entities.map(({id, name}) => ({
        caption: name,
        value: id
    }))

    const invertRouteHandler = useCallback(async (routeId: string) => {
        const resp = await invertRoute({routeId});
        if (!isErrorResponse(resp)) {
            addToast(`${t('common.save-successful')}`, 'success');
        }
    }, [])

    const copyBtnHandler = useCallback(async (routeId: string) => {
        setCopiedRouteId(routeId);
        setModalVisible(true);
    }, [])

    const copyRouteToLine = useCallback(async (event) => {
        const formDataObj = getFilledFieldsObjOnFormSubmit(event);
        const {lineId} = formDataObj;

        const resp = await cloneRouteToLine({routeId: copiedRouteId, lineId: lineId.toString()});
        if (!isErrorResponse(resp)) {
            addToast(`${t('common.save-successful')}`, 'success');
        }

        setModalVisible(false);
    }, [setModalVisible, cloneRouteToLine, copiedRouteId]);

    const mapFeaturesFilterFn = useCallback((entities: any[]) => {
        setMapFilter(["in", ['get', 'id'], ["literal", entities.map(entity => entity.id)]]);
    }, [setMapFilter])

    useEffect(() => {
        return () => {
            setMapFilter([]);
        }
    }, [setMapFilter])

    return (
        <>
            <LoadingBackdrop
                isVisible={loading || linesLoading}
                transparent
            >
                <EntitiesLoader
                    entityName={entityName}
                    serviceName={serviceName}
                    parentId={lineId}
                    form={EntityListRowForm}
                    actions={(routeId: string) => (
                        <>
                            <Tooltip title={t('transit-lines.invert-route')} placement="top">
                                <IconButton
                                    icon={CutIcon}
                                    width={20}
                                    height={20}
                                    onClick={() => invertRouteHandler(routeId)}
                                    borderless
                                />
                            </Tooltip>
                            <Tooltip title={t('transit-lines.copy-route-to-line-modal-title')} placement="top">
                                <IconButton
                                    icon={CopyIcon}
                                    width={20}
                                    height={20}
                                    onClick={() => copyBtnHandler(routeId)}
                                    borderless
                                />
                            </Tooltip>
                        </>
                    )}
                    onEntitiesLoadedFn={mapFeaturesFilterFn}
                />
                {
                    modalVisible ?
                        <Portal targetNodeId={"app"}>
                            <LoadingBackdrop
                                isVisible={loading || linesLoading}
                                transparent
                            >
                                <Modal
                                    title={t("transit-lines.copy-route-to-line-modal-title")}
                                    setVisible={setModalVisible}
                                >
                                    <Form
                                        name="copyRouteToLineForm"
                                        onFormSubmit={copyRouteToLine}
                                        submitBtnTitle={t('common.confirm')}
                                        // disabled={!selectedPresetId}
                                    >
                                        <Row>
                                            <SelectBox.Labeled
                                                label={t('transit-lines.copy-route-to-line-select')}
                                                name='lineId'
                                                options={linesOptions}
                                            />
                                        </Row>
                                    </Form>
                                </Modal>
                            </LoadingBackdrop>
                        </Portal>
                        : null
                }
            </LoadingBackdrop>
        </>
    )
}

export default TransitRoutesList;