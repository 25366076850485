import React, {useCallback, useEffect} from "react";
import {BaseEntityNameLess} from "../../../api/entities/BaseEntity";
import {EntitiesListRowProps} from "../EntitiesListRow";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName, selectSelectedFeatures} from "../../../redux/selectors/selectors";
import {useEntitiesLoader} from "../../../hooks/entites/useEntitiesLoader";
import {isErrorResponse} from "../../../utils/utils";
import EntitiesList from "../EntitiesList/EntitiesList";
import EntitiesControls from "../EntitiesControls/EntitiesControls";
import './entities-loader.scss';
import {setSourceReloadRequired} from "../../../redux/map/map-reducer";
import {useDispatch} from "react-redux";


type Props<T extends BaseEntityNameLess> = {
    entityName: string;
    serviceName: string;
    parentId?: string;
    newEntityUrl?: string;
    showNewButton?: boolean;
    actions?: (id: string) => React.ReactElement;
    onEntitiesLoadedFn?: (entities: T[]) => void;
} & Omit<EntitiesListRowProps<T>, 'entity' | 'deleteFn' | 'onRowClick'>;

const EntitiesLoader = <T extends BaseEntityNameLess>({
                                                          entityName,
                                                          serviceName,
                                                          parentId,
                                                          form,
                                                          newEntityUrl,
                                                          // showEditIcon = true,
                                                          showNewButton = true,
                                                          getUrlFunc,
                                                          actions,
                                                          onEntitiesLoadedFn
                                                      }: Props<T>) => {
    const layerId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const selectedFeatures = useTypedSelector(selectSelectedFeatures);
    const {
        loading,
        deleting,
        entities,
        deleteFn,
        onRowClick,
        searchPhrase,
        setSearch
    } = useEntitiesLoader<T>({
        entityName: entityName!,
        serviceName: serviceName!,
        parentId
    });
    const dispatch = useDispatch();

    useEffect(() => {
        onEntitiesLoadedFn?.(entities);
    }, [entities]);

    const deleteHandler = useCallback(async ({entity}: { entity: T }) => {
        const resp = await deleteFn({entity});
        if (!isErrorResponse(resp) && layerId) {
            dispatch(setSourceReloadRequired({sourceId: layerId, isReloadRequired: true}));
        }
    }, [layerId, deleteFn]);

    return (
        <div className="entities-loader">
            <EntitiesControls
                searchPhrase={searchPhrase}
                onSearch={setSearch}
                newEntityUrl={newEntityUrl}
                showNewButton={showNewButton}
                disableSearchField={deleting || (!searchPhrase && !entities.length)}
            />
            <EntitiesList<T>
                loading={loading || deleting}
                entities={entities}
                onRowClick={onRowClick}
                form={form}
                getUrlFunc={getUrlFunc}
                deleteFn={deleteHandler}
                selectedId={selectedFeatures?.[0]?.id}
                actions={actions}
            />
        </div>
    )
}

export default EntitiesLoader;